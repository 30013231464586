











import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

import ListingCard from '@/components/ListingCard.vue';
import Listings from '../components/Listings.vue';

@Component({
  components: {
    Listings,
  },
})
export default class OffMarketListings extends Mixins(View) {
  private listingFilter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.OffMarket,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.Rural,
      ListingCategory.ResidentialLand,
      ListingCategory.CommercialLand,
      ListingCategory.Commercial,
      ListingCategory.Business,
    ],
    PageSize: 9,
  });

  handleListingsError(ex: Response) {
    if (ex.status === 403) {
      this.$router.push({
        path: '/off-market/register',
        query: {
          reauth: 'true',
          next: '/off-market/properties-for-sale',
        },
      });
    }
  }
}
